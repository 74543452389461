<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厦门市公安局许标旗副局长一行莅临考察福建历思司法鉴定所</div>
      <p class="time">2018.12.07</p>
      <p class="space_content" style="text-indent:0">
        近日，厦门市公安局许标旗副局长、湖里禁毒大队黄思强大队长、湖里分局技术科蔡荣泉科长等领导莅临福建历思司法鉴定所（以下简称历思）进行考察。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/18-1.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        福建历思司法鉴定所目前已获得的司法鉴定资质有12大类，基本覆盖了当前司法鉴定的主要领域，能够提供优质的一站式综合服务。目前在环境物证鉴定、文书及痕迹检验、文书形成时间、声像资料、毒品毒物鉴定、文物艺术品鉴定等方面，检验技术已达国内先进水平。历思长期承担厦门市酒精检测的主要任务；承担了福建省主要涉毒案件鉴定相关鉴定工作，所提供的毛发检测等领先项目和综合鉴定服务深受全国各地欢迎，特别在厦门金砖会议前后，经常加班加点，为会议保障工作提供了优质及时的服务；历思还承担了福建省主要环保案件鉴定工作，解决了环保、国土等部门办理环境案件的燃眉之需。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/18-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/18-3.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        历思以规范求发展，重视设备投入与质量管理。为保障技术研发与服务能力，历思创建了第三方大型物证中心，拥有1500平方米的专业实验室，实验室洁净区达到万级标准，全面配置高端检验设备，具备了进行跨专业综合研究与技术实施的良好基础条件。
      </p>
      <p class="space_content" style="text-indent:0">
        许标旗副局长一行在我所领导带领下，深入了解历思物证中心实验室精密检验设备，观看了现场操作演示并与技术人员进行亲切的沟通讨论，询问实验操作情况、鉴定标准应用以及鉴定实际案例等，并对历思的实验室建设、技术创新、人才梯队培养等方面给予充分的肯定。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/18-4.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/18-5.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        许标旗副局长指出要充分发挥社会力量的作用，以科技手段提高工作效率和服务质量，是当前公安工作改进的重要途径。希望历思能够再接再厉，持续改进，为公安部门提供更加快捷、优质的服务。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/17'"><p class="ellipsis1">协会状告养猪人索赔150万元，历思助力厦门首例环境公益诉讼案！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/19'"><p class="ellipsis1">厦门市司法局叶局长一行调研指导福建历思司法鉴定所</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
